import React, { useState, useRef, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import RotateIcon from '@mui/icons-material/RotateRight'

const FloatingTextEditor = ({
  text,
  fontSize,
  position,
  onTextChange,
  onDragEnd,
  onDelete,
  onRotationChange,
  rotation,
}) => {
  const editorRef = useRef(null)
  const inputRef = useRef(null)
  const [value, setValue] = useState(text)
  const [isEditing, setIsEditing] = useState(false)
  const [isRotating, setIsRotating] = useState(false)
  const initialMousePos = useRef({ x: 0, y: 0 })
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    setValue(text)
  }, [text])

  useEffect(() => {
    if (inputRef.current && !isDragging) {
      inputRef.current.focus()
    }
  }, [isDragging])

  const handleClick = (event) => {
    event.stopPropagation()
  }

  useEffect(() => {
    const rotateText = (event) => {
      if (isRotating && event.buttons === 1) {
        const deltaX = event.clientX - initialMousePos.current.x
        const deltaY = event.clientY - initialMousePos.current.y
        const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI)
        onRotationChange(angle)
      }
    }

    const stopRotation = () => {
      if (isRotating) {
        setIsRotating(false)
      }
    }

    if (isRotating) {
      window.addEventListener('mousemove', rotateText)
      window.addEventListener('mouseup', stopRotation)
      document.body.style.cursor = 'grabbing'
    } else {
      window.removeEventListener('mousemove', rotateText)
      window.removeEventListener('mouseup', stopRotation)
      document.body.style.cursor = 'default'
    }

    return () => {
      window.removeEventListener('mousemove', rotateText)
      window.removeEventListener('mouseup', stopRotation)
      document.body.style.cursor = 'default'
    }
  }, [isRotating, onRotationChange])

  const handleDragStart = (event) => {
    event.stopPropagation()
    setIsDragging(true)
  }

  const handleDragEnd = (event) => {
    setIsDragging(false)
    if (!isRotating) {
      const parentRect = event.target.parentElement.getBoundingClientRect()
      const x = ((event.clientX - parentRect.left) / parentRect.width) * 100
      const y = ((event.clientY - parentRect.top) / parentRect.height) * 100
      onDragEnd({ x, y })
    }
  }

  const handleChange = (e) => {
    setValue(e.target.value)
    onTextChange(e.target.value)
  }

  const handleBlur = () => {
    if (!value.trim()) {
      setValue('')
      onDelete()
    }
  }

  const startRotation = (event) => {
    if (event.button === 0) {
      setIsRotating(true)
      initialMousePos.current = { x: event.clientX, y: event.clientY }
      editorRef.current.draggable = false
    }
  }

  const stopRotation = () => {
    if (isRotating) {
      setIsRotating(false)
      editorRef.current.draggable = true
    }
  }

  return (
    <div
      ref={editorRef}
      style={{
        position: 'absolute',
        top: `${position.y}%`,
        left: `${position.x}%`,
        transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
        fontSize: `${fontSize}px`,
        color: 'white',
        textShadow: '1px 1px 3px rgba(0, 0, 0, 0.6), -1px -1px 3px rgba(0, 0, 0, 0.6)',
        cursor: isRotating ? 'grabbing' : 'move',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        border: isEditing ? '4px dashed #ffd700' : '4px dashed #007bff',
        padding: '5px',
        borderRadius: '4px',
        transition: '.3s',
      }}
      draggable={!isRotating}
      onMouseDown={handleDragStart}
      onDragEnd={handleDragEnd}
      onMouseEnter={() => setIsEditing(true)}
      onMouseLeave={() => setIsEditing(false)}
      onClick={handleClick}
    >
      <IconButton
        size="small"
        sx={{
          position: 'absolute',
          top: '-10px',
          right: '-10px',
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 2,
          '&:hover': {
            backgroundColor: 'rgba(255, 0, 0, 0.7)',
          },
        }}
        onClick={onDelete}
      >
        <CloseIcon fontSize="small" />
      </IconButton>

      <TextField
        inputRef={inputRef}
        variant="outlined"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        InputProps={{
          style: {
            fontSize: fontSize,
            color: 'white',
            backgroundColor: 'transparent',
            textAlign: 'center',
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.6), -1px -1px 3px rgba(0, 0, 0, 0.6)',
            border: 'none',
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
          },
          '& .MuiInputBase-input': {
            textAlign: 'center',
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.6), -1px -1px 3px rgba(0, 0, 0, 0.6)',
          },
        }}
        fullWidth
      />

      <IconButton
        size="small"
        sx={{
          position: 'absolute',
          bottom: '-20px',
          left: '50%',
          transform: 'translateX(-50%)',
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 2,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }}
        onMouseDown={startRotation}
        onMouseUp={stopRotation}
      >
        <RotateIcon fontSize="small" />
      </IconButton>
    </div>
  )
}

export default FloatingTextEditor
