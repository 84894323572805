// GlobalState.js
import React, { useState } from 'react'
import GlobalStateContext from './GlobalStateContext'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

function GlobalStateProvider({ children, appData }) {
  const [globalState] = useState({})

  let app
  let db
  let auth
  let storage

  if (typeof window !== 'undefined' && appData?.firebaseConfig) {
    app = initializeApp(appData.firebaseConfig)
    db = getFirestore(app)
    auth = getAuth(app)
    storage = getStorage(app)
  }
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator?.userAgent);

  const [image, setImage] = useState(null)
  const [fontSize, setFontSize] = useState(isMobile ? 24 : 50)
  const [stripePosition, setStripePosition] = useState(null)
  const [stripeColor, setStripeColor] = useState('white')
  const [stripeHeight, setStripeHeight] = useState(50)
  const [rotation, setRotation] = useState(0)

  const context = {
    globalState,
    appData,
    image,
    setImage,
    fontSize,
    setFontSize,
    stripePosition,
    setStripePosition,
    stripeColor,
    setStripeColor,
    stripeHeight,
    setStripeHeight,
    rotation,
    setRotation,
    isMobile,
    firebase: {
      app,
      db,
      auth,
      storage,
    },
  }

  return <GlobalStateContext.Provider value={context}>{children}</GlobalStateContext.Provider>
}

export default GlobalStateProvider
