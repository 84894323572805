import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Box, Button, Modal, Typography } from '@mui/material'

const ImageUpload = ({ onImageUpload }) => {
  const [open, setOpen] = useState(false)
  const fileInputRef = useRef(null)
  const dropZoneRef = useRef(null)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleFileUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => onImageUpload(reader.result)
      reader.readAsDataURL(file)
      handleClose()
    }
  }

  const handlePaste = useCallback((event) => {
    const items = event.clipboardData.items
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      if (item.type.startsWith('image')) {
        const blob = item.getAsFile()
        const reader = new FileReader()
        reader.onload = () => onImageUpload(reader.result)
        reader.readAsDataURL(blob)
        setOpen(false)
        break
      }
    }
  }, [onImageUpload])

  const handleDrop = (event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => onImageUpload(reader.result)
      reader.readAsDataURL(file)
      handleClose()
    }
  }

  const preventDefault = (event) => event.preventDefault()

  useEffect(() => {
    document.addEventListener('paste', handlePaste)
    return () => document.removeEventListener('paste', handlePaste)
  }, [handlePaste])

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{ textTransform: "capitalize", fontSize: '1.2rem'}}
      >
        Upload Image
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box className="upload-modal">
          <Typography variant="h6" className="upload-modal-title">
            Upload Image
          </Typography>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleFileUpload}
          />
          <Button
            variant="contained"
            onClick={() => fileInputRef.current.click()}
            className="choose-file-btn"
          >
            Choose File
          </Button>
          <Box
            ref={dropZoneRef}
            onDrop={handleDrop}
            onDragOver={preventDefault}
            onDragEnter={preventDefault}
            className="drop-zone"
          >
            Drag & drop your file here or press Ctrl+V to paste
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default ImageUpload
