import React from 'react'
import { AppBar, Toolbar, Typography } from '@mui/material'

const Header = () => {
  return (
    <AppBar position="static" sx={{ background: '#333' }}>
      <Toolbar>
        <Typography
          variant="h6"
          sx={{ flexGrow: 1, textAlign: 'center', fontWeight: '400', letterSpacing: '1.4px' }}
        >
          MemeCraft
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default Header
