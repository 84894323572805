import React, { useState, useContext } from 'react'
import { Button } from '@mui/material'
import GlobalStateContext from './GlobalStateContext'
import ImagePreviewModal from './ImagePreviewModal'

const SaveButton = ({ textFields, stripePosition, stripeColor, stripeHeight }) => {
  const { image, rotation, fontSize } = useContext(GlobalStateContext)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewSrc, setPreviewSrc] = useState(null)

  const handleSaveMeme = () => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = image

    img.onload = () => {
      const imgWidth = img.width
      const imgHeight = img.height
      canvas.width = imgWidth
      canvas.height =
        imgHeight +
        (stripePosition === 'bottom' ? stripeHeight : 0) +
        (stripePosition === 'top' ? stripeHeight : 0)
      context.fillStyle = stripeColor

      if (stripePosition === 'top' || stripePosition === 'both') {
        context.fillRect(0, 0, canvas.width, stripeHeight)
      }
      if (stripePosition === 'bottom' || stripePosition === 'both') {
        context.fillRect(0, canvas.height - stripeHeight, canvas.width, stripeHeight)
      }

      context.drawImage(img, 0, stripePosition === 'top' ? stripeHeight : 0)

      textFields.forEach((field) => {
        const scaleFactor = canvas.width / imgWidth
        const scaledFontSize = field.fontSize * scaleFactor
        context.font = `${scaledFontSize}px Impact`
        context.textAlign = 'center'
        context.textBaseline = 'top'
        context.lineWidth = 2
        context.shadowColor = 'rgba(0, 0, 0, 0.8)'
        context.shadowBlur = 7
        context.shadowOffsetX = 4
        context.shadowOffsetY = 4
        context.fillStyle = 'white'
        const x = (canvas.width * field.position.x) / 100
        const y = (canvas.height * field.position.y) / 100
        context.save()
        context.translate(x, y)
        context.rotate(((rotation || field.rotation || 0) * Math.PI) / 180)
        context.fillText(field.text, 0, 0)
        context.restore()
        context.shadowColor = 'transparent'
      })

      const watermarkText = 'memecraft'
      const scaledWatermarkFontSize = (fontSize / 2) * (canvas.width / imgWidth)
      context.font = `${scaledWatermarkFontSize}px Impact`
      context.fillStyle = 'rgba(255, 255, 255, 0.5)'
      context.textAlign = 'right'
      context.textBaseline = 'bottom'
      context.strokeStyle = 'rgba(0, 0, 0, 0.5)'
      context.lineWidth = 1
      const padding = 10
      const watermarkX = canvas.width - padding
      const watermarkY = canvas.height - padding
      context.strokeText(watermarkText, watermarkX, watermarkY)
      context.fillText(watermarkText, watermarkX, watermarkY)

      const previewDataUrl = canvas.toDataURL('image/png')
      setPreviewSrc(previewDataUrl)
      setPreviewOpen(true)
    }
  }

  const handleFinalSave = () => {
    const link = document.createElement('a')
    link.href = previewSrc
    link.download = 'meme.png'
    link.click()
    setPreviewOpen(false)
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSaveMeme}
        sx={{ background: '#555', '&:hover': { background: '#777' } }}
      >
        Preview
      </Button>
      {previewSrc && (
        <ImagePreviewModal
          open={previewOpen}
          imageSrc={previewSrc}
          onClose={() => setPreviewOpen(false)}
          onSave={handleFinalSave}
        />
      )}
    </>
  )
}

export default SaveButton
