import React from 'react'
import { Box, Button, Typography, Modal } from '@mui/material'

const ImagePreviewModal = ({ open, onClose, imageSrc, onSave }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal-title"
      aria-describedby="preview-modal-description"
    >
      <Box className="preview-container">
        <Typography
          variant="h5"
          gutterBottom
          id="preview-modal-title"
          sx={{ mb: 2, color: '#ddd', textAlign: 'center' }}
        >
          Preview
        </Typography>
        <img src={imageSrc} alt="Preview" style={{ maxWidth: '100%', borderRadius: '4px' }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button variant="contained" color="primary" onClick={onSave}>
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ background: '#ff0000', '&:hover': { background: '#cc0000' }, color: '#ffffff' }}
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ImagePreviewModal
