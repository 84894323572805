import React, { useState, useEffect, useRef, useContext } from 'react'
import {
  Container,
  Grid,
  Box,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Slider,
} from '@mui/material'
import Header from './components/Header'
import ImageUpload from './components/ImageUpload'
import MemeTemplates from './components/MemeTemplates'
import FloatingTextEditor from './components/FloatingTextEditor'
import SaveButton from './components/SaveButton'
import FontSizeSlider from './components/FontSizeSlider'
import { initializeApp } from 'firebase/app'
import { getInternalApiUrl } from './helpers/getInternalApiUrl'
import GlobalStateContext from './components/GlobalStateContext'

function App({ pageProps }) {
  const {
    image,
    setImage,
    fontSize,
    setFontSize,
    stripePosition,
    setStripePosition,
    stripeColor,
    setStripeColor,
    stripeHeight,
    setStripeHeight,
  } = useContext(GlobalStateContext)
  const [textFields, setTextFields] = useState([])
  const topRef = useRef(null)
  const imageContainerRef = useRef(null)
  const [firebaseApp, setFirebaseApp] = useState(null)

  useEffect(() => {
    if (!firebaseApp && pageProps?.appData?.firebaseConfig) {
      const app = initializeApp(pageProps.appData.firebaseConfig)
      setFirebaseApp(app)
    }
  }, [firebaseApp, pageProps])

  const handleImageUpload = (img) => {
    setImage(img)
    setTextFields([])
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleFontSizeChange = (newFontSize) => {
    setFontSize(newFontSize)
    setTextFields((prevFields) =>
      prevFields.map((field) => ({
        ...field,
        fontSize: newFontSize,
      }))
    )
  }

  const handleTextChange = (index, newText) => {
    const newTextFields = [...textFields]
    newTextFields[index].text = newText
    setTextFields(newTextFields)
  }

  const handleRotationChange = (index, newRotation) => {
    const newTextFields = [...textFields]
    newTextFields[index].rotation = newRotation
    setTextFields(newTextFields)
  }

  const skipNextFieldCreationRef = useRef(false)

  const handleImageClick = (event) => {
    if (skipNextFieldCreationRef.current) {
      skipNextFieldCreationRef.current = false
      return
    }

    const rect = imageContainerRef.current.getBoundingClientRect()
    const x = ((event.clientX - rect.left) / rect.width) * 100
    const y = ((event.clientY - rect.top) / rect.height) * 100

    setTextFields((prevFields) => {
      const emptyFieldIndex = prevFields.findIndex((field) => !field.text.trim())
      let updatedFields = prevFields

      if (emptyFieldIndex !== -1) {
        updatedFields = prevFields.filter((_, i) => i !== emptyFieldIndex)
      }

      const newTextField = {
        text: '',
        position: { x, y },
        fontSize: fontSize,
        rotation: 0,
      }
      return [...updatedFields, newTextField]
    })
  }

  const handleDeleteField = (index) => {
    skipNextFieldCreationRef.current = true
    setTextFields((prevFields) => prevFields.filter((_, i) => i !== index))
  }

  const handleDragEnd = (index, coords) => {
    const newTextFields = [...textFields]
    newTextFields[index].position = coords
    setTextFields(newTextFields)
  }

  const handleReset = () => {
    setTextFields([])
  }

  const renderStripe = () => {
    if (!stripePosition) return null

    const stripeStyle = {
      backgroundColor: stripeColor,
      width: '100%',
      height: `${stripeHeight}px`,
      position: 'absolute',
      left: 0,
      [stripePosition]: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }

    return <div style={stripeStyle}></div>
  }

  return (
    <>
      <Header />
      <Container sx={{
        mt: 5,
        '@media (max-width: 780px)' : {
          padding: '8px'
        }
      }}>
        <div ref={topRef}></div>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <ImageUpload onImageUpload={handleImageUpload} />
          </Grid>

          {image && (
            <Grid item xs={12} container spacing={3} className="grid-container">
              <Grid item xs={8} className="grid-image">
                <Box ref={imageContainerRef} onClick={handleImageClick} className="meme-container">
                  {renderStripe()}
                  <img
                    src={image}
                    alt="Meme"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                      borderRadius: '10px',
                    }}
                  />
                  {textFields.map((field, index) => (
                    <FloatingTextEditor
                      key={index}
                      text={field.text}
                      fontSize={field.fontSize}
                      position={field.position}
                      rotation={field.rotation}
                      onTextChange={(newText) => handleTextChange(index, newText)}
                      onDragEnd={(coords) => handleDragEnd(index, coords)}
                      onDelete={() => handleDeleteField(index)}
                      onRotationChange={(newRotation) => handleRotationChange(index, newRotation)}
                    />
                  ))}
                </Box>
              </Grid>
              <Grid item xs={4} className="grid-redactor">
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <FontSizeSlider fontSize={fontSize} setFontSize={handleFontSizeChange} />
                  <Typography gutterBottom sx={{ color: '#0057b7' }}>
                    Stripe Height
                  </Typography>
                  <Slider
                    value={stripeHeight}
                    onChange={(e, newValue) => setStripeHeight(newValue)}
                    aria-labelledby="stripe-height-slider"
                    min={10}
                    max={200}
                    sx={{ color: '#0057b7' }}
                  />
                  <Typography gutterBottom sx={{ color: '#0057b7' }}>
                    Stripe Position
                  </Typography>
                  <RadioGroup
                    value={stripePosition}
                    onChange={(e) => setStripePosition(e.target.value)}
                    row
                  >
                    <FormControlLabel value="top" control={<Radio />} label="Top" />
                    <FormControlLabel value="bottom" control={<Radio />} label="Bottom" />
                    <FormControlLabel value="both" control={<Radio />} label="Both" />
                    <FormControlLabel value={null} control={<Radio />} label="None" />
                  </RadioGroup>
                  <Typography gutterBottom sx={{ color: '#0057b7' }}>
                    Stripe Color
                  </Typography>
                  <RadioGroup
                    value={stripeColor}
                    onChange={(e) => setStripeColor(e.target.value)}
                    row
                  >
                    <FormControlLabel value="white" control={<Radio />} label="White" />
                    <FormControlLabel value="black" control={<Radio />} label="Black" />
                  </RadioGroup>
                  <SaveButton
                    image={image}
                    textFields={textFields}
                    fontSize={fontSize}
                    stripePosition={stripePosition}
                    stripeColor={stripeColor}
                    stripeHeight={stripeHeight}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleReset}
                    sx={{
                      background: '#ff0000',
                      '&:hover': { background: '#cc0000' },
                      color: '#ffffff',
                    }}
                  >
                    Reset Text
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <MemeTemplates onSelectTemplate={handleImageUpload} />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

App.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {}

  if (Component?.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }

  if (ctx.req) {
    const appData = await fetch(getInternalApiUrl(ctx.req, '/api/get-app-data')).then(
      (appDataRes) => appDataRes.json()
    )
    pageProps.appData = appData
  }

  return { pageProps }
}

export default App
