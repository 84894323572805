import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Container, Grid, Card, CardMedia, CardContent, Typography, Divider } from '@mui/material'
// import { getAllImagesFromFirebase } from '../apiHandlers'

const MemeTemplates = ({ onSelectTemplate }) => {
  const [templates, setTemplates] = useState([])
  // const [
  //   firebaseTemplates,
  //   setFirebaseTemplates
  // ] = useState([])

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get('https://api.imgflip.com/get_memes')
        setTemplates(response?.data?.data?.memes)
      } catch (error) {
        console.error('Error fetching meme templates:', error)
      }
    }

    // const fetchFirebaseTemplates = async () => {
    //   try {
    //     const images = await getAllImagesFromFirebase()
    //     setFirebaseTemplates(images)
    //   } catch (error) {
    //     console.error('Error fetching images from Firebase:', error)
    //   }
    // }

    fetchTemplates()
    // fetchFirebaseTemplates()
  }, [])

  return (
    <Container sx={{
      mt: 5,
      '@media (max-width: 780px)' : {
        mt: 2,
        p: 0,
    }}}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          color: '#ddd',
          textAlign: 'center',
          '@media (max-width: 780px)' : {
            fontSize: '1.6rem'
          }
        }}>
        Available Meme Templates
      </Typography>
      {/* <Divider sx={{ backgroundColor: '#666', margin: '40px 0' }}>Firebase Templates</Divider> */}
      {/* <Grid container spacing={3}>
        {firebaseTemplates?.map((template) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={template.id}>
            <Card
              onClick={() => onSelectTemplate(template.url)}
              sx={{
                cursor: 'pointer',
                border: '1px solid #666',
                '&:hover': { boxShadow: '0 0 10px #666' },
              }}
            >
              <CardMedia component="img" height="200" image={template.url} alt={template.name} />
              <CardContent sx={{ backgroundColor: '#444', color: '#ddd' }}>
                <Typography variant="h6">{template.name}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid> */}
      <Divider
        sx={{
          backgroundColor: '#666',
          margin: '40px 0',
          '@media (max-width: 780px)': {
            margin: '16px 0'
          }
        }
      }>Top Templates</Divider>
      <Grid container spacing={{ xs: 1, md: 3 }}
        sx={{'@media (max-width: 780px)': {
          padding: '0'
        }
      }}>
        {templates?.map((template, index) => (
          <Grid item xs={6} sm={6} md={4} lg={3} key={template.id}>
            <Card
              onClick={() => onSelectTemplate(template.url)}
              sx={{
                cursor: 'pointer',
                border: '1px solid #666',
                '&:hover': { boxShadow: '0 0 10px #666' },
              }}
            >
              <CardMedia
                loading={index > 8 ? 'lazy' : 'eager'}
                component="img"
                height="200"
                image={template.url} alt={template.name}
              />
              <CardContent sx={{
                backgroundColor: '#444',
                color: '#ddd',
                '@media (max-width: 780px)': {
                  padding: '8px !important',
                }}}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: '1rem',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    '@media (max-width: 780px)': {
                      margin: 0,
                    }
                  }}>{template.name}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default MemeTemplates
